import React, { useContext} from 'react';
import {Helmet} from "react-helmet";
import {Link } from "react-router-dom";
import { ConfigContext } from '../../context/ConfigContext';

const Intro = () => {
    const {config} = useContext(ConfigContext)
    return (
    <>
        <Helmet>
            <title>{config.page_title}</title>
            <meta name="description" content={config.page_description}/>
            <link rel="image_src" href={config.logo}/>
            <meta property="og:type" content="website"/>
			<meta property="og:title" content={config.page_title} />
			<meta property="og:description" content={config.page_description} />
			<meta property="og:url" content="https://corazondeorohechoenmexico.com.mx" />
			<meta property="og:image" content={config.logo} />
            <link rel="canonical" href="https://corazondeorohechoenmexico.com.mx"/>
        </Helmet>
        
        <div className='m-0 bg-cover' style={{background:'url(../../Intro/corazon-de-oro-1.jpg), no-repeat', minHeight:'100vh',}}>
            <div className="m-p-0 position-relative container-intro">
                <div className="m-0 position-absolute top-0 start-0" style={{width:'100%', }}>
                    <nav className="navbar navbar-expand-lg navbar-light" style={{background:'rgba(0, 0, 0, .7)'}}>
                        <div className="container-fluid">
                            <button className="navbar-toggler btn-menuIntro"
                             type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <div className="collapse navbar-collapse menu-intro" id="navbarNav">
                                <ul className="navbar-nav row ">
                                    <li className="nav-item col-md-auto">
                                        <Link className=" active" aria-current="page" to="/casa">Casa</Link>
                                    </li>
                                    <li className="nav-item col-md-auto">
                                        <Link className="" to="/nosotros">Nosotros</Link> 
                                    </li>
                                    <li className="nav-item col-md-auto">
                                        <Link className="" to="/servicios">Servicios</Link> 
                                    </li>
                                    <li className="nav-item col-md-auto">
                                        <Link className="" to="/servicios">Eventos Sociales y Corporativos</Link> 
                                    </li>
                                    <li className="nav-item col-md-auto">
                                        <Link className="" to="/notas">Notas de Amor</Link> 
                                    </li>
                                    <li className="nav-item col-md-auto">
                                        <Link className="" to="/contacto">Contacto</Link> 
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>

                <div className="m-0 position-absolute bottom-0 start-0 centrar">
                    <div className='container-text'> 
                       <p className='cont-textos-cursiva text-animated'>
                            <span>Una historia </span><span> única</span><span> de</span><span> amor,</span><span> contada</span><span></span>
                            <span> a </span><span> través </span><span> del</span><span> tiempo....</span>&nbsp;&nbsp;<br></br>
                            <span> Si </span>&nbsp;&nbsp;<span> Si Quiero! ...</span><br></br>
                            <span>Hagamos</span><span> juntos,</span><span> este</span><span> sueño realidad!</span><br></br>
                        </p>
                    </div>
                </div>
                <div className="m-0 position-absolute bottom-0 end-0 ">
                    <div className='m-0 logo-dorado'> 
                        <img src='../../Intro/logo-corazon-de-oro.png' />
                    </div>
                </div>
            </div>
        </div>
    </>
    );
};

export default Intro;