import './App.scss';
import {
	BrowserRouter,
	Routes,
	Route,
  } from "react-router-dom";
  // import your route components too
import Intro from './componets/Intro/Intro';
import Casa from './componets/Casa/Casa';
import Nosotros from './componets/Nosotros/Nosotros';
import Servicios from './componets/Servicios/Servicios';
import Contacto from './componets/Contacto/Contacto';
import Servicio from './componets/Servicio/Servicio';
import {ConfigProvider} from './context/ConfigContext';
import Notas from './componets/Notas/Notas';

function App() {
  return (
	<ConfigProvider>
		<div className="App">
			<BrowserRouter>
				<Routes>
					<Route path="/" element={<Intro />}> </Route>
					<Route path="/casa" element={<Casa></Casa>}> </Route>
					<Route path="/nosotros" element={<Nosotros/>}> </Route>
					<Route path='/servicios' element={<Servicios tipo={'servicio'}/>}></Route>
					<Route path='/eventos' element={<Servicios tipo={'evento'}/>}></Route>
					<Route path='/servicio/:id' element={<Servicio/>}></Route>
					<Route path="/notas" element={<Notas/>}> </Route>
					<Route path='/contacto' element={<Contacto/>}></Route>
				</Routes>
				
			</BrowserRouter>
		</div>
	</ConfigProvider>
  );
};

export default App;