import React, { Fragment, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Footer from '../Footer/Footer';
import Menu from '../Menu/Menu';
import { Link, useParams } from 'react-router-dom';
import axios from '../../axios';

// import Swiper core and required modules
import { Navigation, A11y, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import parse from 'html-react-parser';

const Servicio = () => {
    const {id} = useParams();
    const [servicio,setServicio] = useState({nombre:'',descripcion_corta:''});
    const [imagenes,setImagenes] = useState([]);
    const [servicios,setServicios] = useState([]);
   
    useEffect(()=>{
        axios.get(`api/servicios/${id}`)
        .then(res=>{
            console.log(res.data)
            setServicio(res.data)
            setImagenes(res.data.galerias)
        }).catch(e=>console.log("Error",e))
    },[id])

    useEffect(()=>{
        axios.get(`api/getallservicios`)
        .then(res=>{
            setServicios(res.data)
        }).catch(e=>console.log("Error",e))
    },[id])

    return (
    <Fragment>
         <Helmet>
            <title>{servicio.nombre}</title>
            <meta name="description" content={servicio.descripcion_corta}/>
            <link rel="image_src" href={servicio.banner}/>
            <meta property="og:type" content="website"/>
			<meta property="og:title" content={servicio.nombre} />
			<meta property="og:description" content={servicio.descripcion_corta} />
			<meta property="og:image" content={servicio.banner} />
            <link rel="canonical" href="https://corazondeorohechoenmexico.com.mx"/>
        </Helmet>

        <div className=' m-p-0'>
            <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header" style={{border:'none',marginBottom:0,paddingBottom:0,}}>
                            <div style={{width:'100%'}}>
                                <div className='centrar' style={{width:'100%',}}> 
                                    <img src='../../Intro/logotipo_corazon-de-oro.png' style={{height:'100px'}} /> 
                                </div>
                            </div>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body" style={{marginTop:0,paddingTop:0,}}>
                            <div className='container centrar' style={{width:'100%',}}> 
                                <div className='row w-100 m-p-0'>
                                    {servicio.evento_social_corporativo !=='1'&&
                                     <div className='col-2 col-sd-2 col-md-2 m-p-0 centrar'>
                                        <img className='icon-1-2' src='../../bg/icon-1.png' />
                                    </div>
                                    }
                                    <div className='col-8 col-sd-8 col-md-8  m-p-0 info centrar'>
                                        <p className='m-p-0 text-c f-s-18 l-h-1-5 font-gral' style={{color:'#814722', fontWeight:600,}}>
                                            Galeria de {servicio.nombre}
                                        </p>
                                    </div>
                                    {servicio.evento_social_corporativo !=='1'&&
                                    <div className='col-2 col-sd-2 col-md-2 m-p-0 centrar'>
                                        <img className='icon-1-2' src='../../bg/icon-2.png' />
                                    </div>
                                    }
                                </div>
                            </div>
                            <div className='m-p-0'>
                                <div id="carouselExampleIndicators" className="carousel carousel-dark slide" data-bs-ride="carousel">
                                    <div className="carousel-indicators">
                                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                                    </div>
                                    <div className="carousel-inner">
                                        {imagenes.map((img,i)=>
                                            i===0?
                                            <div className="carousel-item centrar active"  style={{height:'80vh', width:'100%'}} key={img.id}>
                                                <img src={img.image}
                                                className='position-absolute top-50 start-50 translate-middle'
                                                style={{objectFit:'fill!important', maxHeight:'80vh', maxWidth:'100%',}} alt={'corazón de oro - '+servicio.nombre}/>
                                            </div>
                                            :
                                            <div className="carousel-item centrar"  style={{height:'80vh', width:'100%'}} key={img.id}>
                                                <img src={img.image}
                                                className='position-absolute top-50 start-50 translate-middle'
                                                style={{objectFit:'fill!important', maxHeight:'80vh', maxWidth:'100%'}} alt={'corazón de oro - '+servicio.nombre}/>
                                            </div>
                                         )}
                                </div>
                                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Previous</span>
                                </button>
                                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Next</span>
                                </button>
                            </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Menu/>

            <div className='' style={{boxShadow:' 0px 0px 50px 0px rgb(0 0 0 / 75%)',backgroundColor:'#ffffff',position:'absolute', zIndex:1, marginTop:'150px', width:'100%',}}>
                <div className='container' id="servicios">
                    <Swiper
                    // install Swiper modules
                    modules={[Navigation, A11y, Autoplay]}
                    autoplay={true}
                    spaceBetween={0}
                    slidesPerView={5}
                    navigation
                    breakpoints={{
                        50: {
                            slidesPerView: 1,
                            spaceBetween: 0,
                          },
                        640: {
                          slidesPerView: 2,
                          spaceBetween: 0,
                        },
                        767: {
                          slidesPerView: 3,
                          spaceBetween: 0,
                        },
                        1024: {
                          slidesPerView: 3,
                          spaceBetween: 0,
                        },
                        1360: {
                            slidesPerView: 5,
                            spaceBetween: 0,
                          },
                      }}
                    >
                        {servicios.map((serv,idnx)=>
                            <SwiperSlide key={serv.id}>
                              <Link to={"/servicio/"+serv.id}>             
                                  <div className='cont-list-services'>
                                      <div className='service-name'>{serv.nombre}</div>
                                  </div> 
                              </Link>
                          </SwiperSlide>
                        )}
                      
                        
                        {/*<SwiperSlide> <a> 
                            <div className='cont-list-services'>
                                <div className='service-name'>  Slide titulo y nombre de el servicio 6</div>
                                <div className='service-icon'>
                                    <div><span>VER SERVICIO</span><i className="fa-solid fa-arrow-right-long"></i></div>
                                </div>
                            </div> 
                                        </a> </SwiperSlide>*/}
                    </Swiper>
                </div>
            </div>

            <div className='row m-p-0'>
                <div className='col-sm-12 col-md-9 m-p-0 row gral-banner' 
                    style={{background:'url('+servicio.banner+'), no-repeat',}}>
                    <img className='movil' src='../../bg/bg-marco.png' style={{marginLeft:0,}} alt="marco"/>
                </div>
                <div className='col-sm-12 col-md-3 m-p-0'>
                    <div className='col-sm-12 col-md-3 m-p-0 position-absolute bottom-0 end-0 centrar'>
                        <div className='m-p-0'>
                            <h2 className='p-40 font-gral f-s-40' style={{color:'#814722', textTransform:'uppercase', marginLeft:'-30px!important',}}> 
                                <span className='m-p-0 nombreServicio' style={{width:'100%'}}>{servicio.nombre}</span>
                            </h2>
                        </div>
                    </div>
                </div>
            </div>

          
            <div className='m-p-0' style={{background:servicio.evento_social_corporativo!=='1'? '':servicio.service_background_color,width:'100%'}}>
                <div className='container p-t-b-20'>
                    <div className='row w-100 m-p-0'>
                        <div className='col-2 col-sm-2 col-md-2 m-p-0 centrar'>   
                            {servicio.evento_social_corporativo !=='1'&&
                            <img className='icon-1-2' src='../../bg/icon-1.png' alt="icono flor"/> 
                            }
                        </div>
                        <div className='col-8 col-md-8 m-p-0 info centrar'>
                            <p className='m-p-0 text-c font-semic f-s-18 l-h-1-5' style={{color:servicio.evento_social_corporativo!=='1'?'':servicio.service_background_text}}>
                            {servicio.titulo}
                            </p>
                        </div>
                        <div className='col-2 col-sm-2 col-md-2 m-p-0 centrar'>
                            {servicio.evento_social_corporativo !=='1'&&
                            <img className='icon-1-2' src='../../bg/icon-2.png' alt ="icono flor 2"/>
                            }
                        </div>
                    </div>
                </div>

                <div className='container'>
                    <div className='m-p-0 f-gral'>
                        <div className='f-gral p-t-b-20 text-c'  
                        style={{paddingTop:'0!important', color:servicio.evento_social_corporativo!=='1'?'':servicio.service_background_text}}>{parse(servicio.descripcion_larga ? servicio.descripcion_larga:"")}</div>
                    </div>
                </div>
            </div>

            <div className='row s-detalle m-p-0 w-100'>
                <div className='col-sm-12 col-md-5 m-p-0 row gral-banner' 
                    style={{background:'url('+servicio.img_1+'), no-repeat',}}>
                </div>
                <div className='col-sm-12 col-md-7 row'
                    style={{background:servicio.evento_social_corporativo!== '1'?'url(../../bg/bg-3.png), no-repeat':servicio.service_background_color}}>
                    
                    <div className='col-md-1'></div>
                    <div className='col-sd-12 col-md-10 p-16'>
                        
                        <div className='cont-img movil'>
                            <div>
                                <img src={servicio.img_2} alt={servicio.nombre} />
                            </div>
                        </div>
                        <div className='cont-text'>
                            <div className='f-gral p-t-b-60' style={{color:servicio.evento_social_corporativo!=='1'?'':servicio.service_background_text}}>{parse(servicio.descripcion_corta ? servicio.descripcion_corta:"")}</div>
                        </div>
                        {servicio.galerias&&servicio.galerias.length > 0 &&
                            servicio.evento_social_corporativo !== '1'?
                            <div className='m-p-0 f-gral movil-center'>
                                <button type="button" className="" data-bs-toggle="modal" data-bs-target="#staticBackdrop"
                                style={{border: 'solid transparent', background: 'transparent'}}>
                                  <div className='row m-p-0'>
                                      <div className='col-2 col-md-2 m-p-0 centrar'>
                                          <img src='../../bg/icon-3.png' style={{height:30}} />
                                      </div>
                                      <div className='col-8 col-md-8 m-p-0 centrar f-s-14'
                                      style={{color:'#814722', fontWeight:600, cursor:'pointer'}}>
                                          <p className='m-p-0 font-semic'>
                                              <span className='m-p-0 font-cursiva'>I</span>r a galeria de {servicio.nombre}</p>
                                      </div>
                                      <div className='col-2 col-md-2 m-p-0 centrar'>
                                          <img src='../../bg/icon-4.png' style={{height:30}} />
                                      </div>
                                  </div>
                                </button>
                            </div>:
                          <div className='row'>
                                <div className='col-12 font-semic'>
                                    <button type="button" className="" data-bs-toggle="modal" data-bs-target="#staticBackdrop" 
                                      style={{color:servicio.service_background_text, fontWeight:500, cursor:'pointer',fontSize:20,border: 'solid transparent', background: 'transparent'}}>
                                          Ir a galeria de {servicio.nombre}
                                    </button>
                                </div>
                          </div>
                        }
                    </div>
                    <div className='col-md-1'></div>
                </div>
                        <div className='centrar m-p-0 see-movil'>
                            <div className='m-p-0' style={{height:'350px'}}>
                                <img src={servicio.img_2} style={{height:'100%', width:'100%', objectFit:'fill'}} alt={servicio.nombre} />
                            </div>
                        </div>
            </div>

            <Footer/>
        </div>
    </Fragment>
    );
};

export default Servicio;