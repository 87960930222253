import React, { useContext, useEffect, useState } from 'react';
import axios from '../../axios';
import Footer from '../Footer/Footer';
import Menu from '../Menu/Menu';
import { Toast } from 'bootstrap';
import { ConfigContext } from '../../context/ConfigContext';
import { Helmet } from 'react-helmet';

const Contacto = () => {
    const [data,setData] = useState({nombre:"",email:"",telefono:"",asunto:"",detalle:""});
    const [toast, setToast] = useState({})
    const [toastMessage,setToastMessage] = useState('')
    const [bgcolor,setBgColor] = useState("#7ed6a5")
    const {config} = useContext(ConfigContext)

    useEffect(()=>{
        var toastEl = document.getElementById('myToast');
        setToast(new Toast(toastEl));
    },[])
   
    const _sendMail = (e) => {
        e.preventDefault()
        axios.post('api/send-message',data)
        .then(res=>{
           if(res.status === 200){
            setToastMessage("Tu mensaje ha sido enviado, prontro nos pondremos en contacto con tigo")
            setBgColor('#7ed6a5')
           }else{
            setToastMessage("Tu mensaje no se ha podido enviar, intenta nuevamente mas tarde")
            setBgColor('#f1926e')
           } 
           toast.show()
        }).catch((e)=>console.log(e))
    }

    return (
    <>

            <Helmet>
                <title>Contacto | {config.page_title ? config.page_title : ''}</title>
                <meta name="description" content={config.home_text ? config.home_text : ''}/>
                <link rel="image_src" href={config.home_img ? config.home_img : ''}/>
                <meta property="og:type" content="website"/>
                <meta property="og:title" content={config.page_title ? config.page_title : ''} />
                <meta property="og:description" content={config.home_text ? config.home_text : ''} />
                <meta property="og:image" content={config.home_img ? config.home_img : ''} />
                <link rel="canonical" href="https://corazondeorohechoenmexico.com.mx"/>
            </Helmet>
        <div className='m-p-0'>
            <Menu></Menu>

            <div id="myToast" className="toast text-white border-0 position-absolute" 
            style={{top:120,right:50,zIndex:9999,backgroundColor:bgcolor}}
            role="alert" aria-live="assertive" aria-atomic="true">
                <div className="d-flex">
                    <div className="toast-body">
                    {toastMessage}
                    </div>
                    <button type="button" className="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
                </div>
            </div>

            <div className='row m-p-0'>
                <div className='col-12 col-sm-12 col-md-6 m-p-0 centrar'>
                    <div className='container p-t-b-60'>
                        <div className='row w-100 m-0 p-t-100'>
                            <div className='col-2 m-p-0 centrar'>
                                <img className='text-decorator-i' src='../../bg/icon-1.png' alt="icon-i"/>
                            </div>
                            <div className='col-8 m-p-0 info centrar'>
                                <p className='m-0 text-c font-cursiva l-h-1-5 text-xxl'>Envianos un Mensaje</p>
                            </div>
                            <div className='col-2 m-p-0 centrar'>
                                <img className='text-decorator-d' src='../../bg/icon-2.png' alt="icon-d"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-sm-12 col-md-6 m-p-0 centrar movil' style={{background:'#e1d7c4',}}>
                    <div className='m-0 contacto-title-container centrar' style={{background:'#e1d7c4', width:'100%',}}> 
                        <h2 className='m-0 text-xxl contacto-title'> CONTACTO </h2> 
                    </div>
                </div>
            </div>
            <div className='row m-p-0'>
                <div className='col-12 col-sm-12 col-md-12 col-lg-6 m-p-0 centrar p-contacto' style={{background:'#e1d7c4'}}>
                    <div className='centrar m-0 form-container' style={{background:'#e1d7c4', minHeight:'100%', width:'100%',}}> 
                    <form className='col col-md-8 p-2' onSubmit={_sendMail}>
                        <div className="col m-0">
                            <div className='m-0 p-t-b-10'>
                                <div className='m-0 p-t-b-5' style={{borderBottom:'solid #a07861 5px'}}>
                                    <input type="text" className="form-control" id="nombre"
                                    placeholder='Nombre'
                                    onChange={(e)=>setData({...data,nombre:e.target.value})}
                                    style={{padding:10, background:'#ffffff!important'}} />
                                </div>
                            </div>
                            <div className='m-0 p-t-b-10'>
                                <div className='m-0 p-t-b-5' style={{borderBottom:'solid #a07861 5px'}}>
                                    <input type="text" className="form-control" id="mail"
                                    placeholder='E-mail'
                                    onChange={(e)=>setData({...data,email:e.target.value})}
                                    style={{padding:10, background:'#ffffff!important'}} />
                                </div>
                            </div>
                            <div className='m-0 p-t-b-10'>
                                <div className='m-0 p-t-b-5' style={{borderBottom:'solid #a07861 5px'}}>
                                    <input type="text" className="form-control" id="tel"
                                    placeholder='Telefono'
                                    onChange={(e)=>setData({...data,telefono:e.target.value})}
                                    style={{padding:10, background:'#ffffff!important'}} />
                                </div>
                            </div>
                            <div className='m-0 p-t-b-10'>
                                <div className='m-0 p-t-b-5' style={{borderBottom:'solid #a07861 5px'}}>
                                    <input type="text" className="form-control" id="asunto"
                                    placeholder='Asunto'
                                    onChange={(e)=>setData({...data,asunto:e.target.value})}
                                    style={{padding:10, background:'#ffffff!important'}} />
                                </div>
                            </div>
                            <div className='m-0 p-t-b-10'>
                                <div className='m-0 p-t-b-5' style={{borderBottom:'solid #a07861 5px'}}>
                                    <textarea type="text" className="form-control" id="detail"
                                    placeholder='Detalle'
                                    onChange={(e)=>setData({...data,detalle:e.target.value})}
                                    style={{padding:10, background:'#ffffff!important'}} ></textarea>
                                </div>
                            </div>
                        </div>
                        <button type="submit" className="btn m-0 p-t-b-60 btn-contacto">Enviar Mensaje</button>
                    </form>
                    </div>
                </div>

                <div className='row m-p-0 see-movil'>
                    <div className='col-12 col-sm-12 col-md-6 m-p-0 centrar' style={{background:'#e1d7c4',}}>
                        <div className='m-0 contacto-title-container centrar' style={{background:'#e1d7c4', width:'100%',}}> 
                            <h2 className='m-0 text-xxl contacto-title'> CONTACTANOS </h2> 
                        </div>
                    </div>
                </div>

                <div className='col-12 col-sm-12 col-md-12 col-lg-6 m-p-0 centrar bg-cover contacatnos p-contacto' 
                    style={{background:'url(../../bg/contacto.png), no-repeat',}}>
                    <div className='col col-sm-12 col-md-9 m-0 p-t-b-60'>
                        <div className='m-0 p-10' style={{}}>
                             <a className="m-0 p-10 text-decoration-none" href={'https://wa.me/52'+config.phone+'?text=Me%20gustaria%20conocer%20mas%20de%20sus%20servicios'} target="_blank" rel='noreferrer'>                      
                                <p style={{fontWeight:800}}> <i className="fa-brands fa-whatsapp f-s-40"></i> &nbsp; &nbsp; {config.phone}</p>
                            </a>
                        </div>
                        {config.url_insta&&
                        <div className='m-0 p-10' style={{}}>
                            <a className="f-s-14  m-p-0 text-decoration-none" href="#">                        
                                <p style={{fontWeight:800}}> <i className="fa-brands fa-instagram f-s-40"></i> &nbsp; &nbsp; {config.url_insta}</p>
                            </a>
                        </div>
                        }
                        {config.mail&&
                        <div className='m-0 p-10' style={{}}>
                            <a className="f-s-14  m-p-0 text-decoration-none" href="#">                        
                                <p style={{fontWeight:800}}> <i className="fa-regular fa-envelope f-s-40"></i> &nbsp; &nbsp; 
                                {config.mail}
                                </p>
                            </a>
                        </div>
                        }
                        {config.mail_receiver&&
                        <div className='m-0 p-10' style={{}}>
                            <a className="f-s-14  m-p-0 text-decoration-none" href="#">                        
                                <p style={{fontWeight:800}}> <i className="fa-regular fa-envelope f-s-40"></i> &nbsp; &nbsp; 
                                {config.mail_receiver}
                                </p>
                            </a>
                        </div>
                        }
                        {config.mail_receiver_2&&
                        <div className='m-0 p-10' style={{}}>
                            <a className="f-s-14  m-p-0 text-decoration-none" href="#">                        
                                <p style={{fontWeight:800}}> <i className="fa-regular fa-envelope f-s-40"></i> &nbsp; &nbsp;  {config.mail_receiver_2}</p>
                            </a>
                        </div>
                        }
                       
                    </div>
                </div>
            </div>
            
            <Footer></Footer>
        </div>
    </>
    );
};

export default Contacto;