import React,{useContext, useEffect,useState} from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import Footer from '../Footer/Footer';
import Menu from '../Menu/Menu';
import axios from '../../axios';
import { ConfigContext } from '../../context/ConfigContext';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/zoom';
import 'swiper/css/autoplay';
import { Navigation, Pagination, Scrollbar, A11y, Zoom, Autoplay} from 'swiper';
import Galeria from '../Galeria/Galeria';
import { Helmet } from 'react-helmet';

const Casa = () => {
    const {config} = useContext(ConfigContext)
    const [sliders,setSliders] = useState([])
   
    useEffect(()=>{
        axios.get('api/carousels').then(res=>{
            setSliders(res.data)
        }).catch(e=>console.log(e))
    },[])

    return (
        <div>
            <Helmet>
                <title>Casa | {config.page_title ? config.page_title : ''}</title>
                <meta name="description" content={config.home_text ? config.home_text : ''}/>
                <link rel="image_src" href={config.home_img ? config.home_img : ''}/>
                <meta property="og:type" content="website"/>
                <meta property="og:title" content={config.page_title ? config.page_title : ''} />
                <meta property="og:description" content={config.home_text ? config.home_text : ''} />
                <meta property="og:image" content={config.home_img ? config.home_img : ''} />
                <link rel="canonical" href="https://corazondeorohechoenmexico.com.mx"/>
            </Helmet>
            
            <Menu logo={config.logo}></Menu>

            <div className='container-slider'>
                <Swiper
                    // install Swiper modules
                    modules={[Navigation, Pagination, Scrollbar, A11y, Zoom, Autoplay]}
                    spaceBetween={0}
                    speed={1000}
                    slidesPerView={1}
                    zoom={true}
                    autoplay={{
                        delay: 6500,
                        disableOnInteraction: false,
                        }}
                    navigation
                    >
                    {sliders.map((slider)=>
                        <SwiperSlide key={slider.id}>
                            <div className='row slider-casa' 
                            style={{background:'url('+slider.image+'), no-repeat'}}>
                                <div className='col-sm-12 col-md-6 casa-i'>
                                    <p className='text-slider-i'>{slider.description}</p>
                                </div>
                                <div className='col-sm-12 col-md-6 casa-d'>
                                    <div className='m-p-0 w-100'
                                    style={{
                                        textAlign:'right'}}>
                                        <p className='text-slider-d'>{slider.description_2}</p>
                                    </div>
                                </div>
                                <div className='col-sm-12 casa-c'>
                                    <p className='text-slider-c'>{slider.description_3}</p>
                                </div>
                            </div>
                        </SwiperSlide>
                    )}
                    
                    
                </Swiper>
            </div>
            <div className='row m-p-0'>
                <div className='col-sm-12 col-md-5 casa-corazon-de-oro-i' 
                style={{background:'url(../../bg/bg-1.png), no-repeat',}}>
                    <div className='row w-100 m-p-0'>
                        <div className='col-md-2  m-p-0'></div>
                        <div className='col-md-9 info'>
                            <img src={config.logo} />
                            <p className='p-t-b-20'>{config.home_text}</p>
                        </div>
                        <div className='col-md-1  m-p-0'></div>
                    </div>
                </div>
                <div className='col-sm-12 col-md-7 m-p-0'>
                    <div className='row m-p-0'>
                        <div className='col-md-1 m-p-0'></div>
                        <div className='col-md-10 casa-corazon-de-oro-d' 
                        style={{background:'url(../../bg/bg-2.png), no-repeat', }}>
                            <div className='col-md-12'>
                                <img  className='' src={config.home_img} />
                            </div>
                        </div>
                        <div className='col-md-1  m-p-0'></div>
                    </div>
                </div>
            </div>

            <div className='p-15'></div>

            <div className='container p-t-b-60 cont-100'>
                <div className='row w-100 m-p-0'>
                    <div className='col-2 col-sm-2 col-md-2 m-p-0 centrar'>
                        <img className='icon-1-2' src='../../bg/icon-1.png' />
                    </div>
                    <div className='col-8 col-sm-8 col-md-8 m-p-0 info centrar'>
                        <p className=' m-p-0 text-c font-semic f-s-18 l-h-1-5'>{config.home_frace}</p>
                    </div>
                    <div className='col-2 col-sm-2 col-md-2 m-p-0 centrar'>
                        <img className='icon-1-2' src='../../bg/icon-2.png' />
                    </div>
                </div>
            </div>
            <Galeria></Galeria>
            <Footer></Footer>
        </div>
    );
};

export default Casa;