import React,{useContext, useEffect, useState} from 'react';
import Footer from '../Footer/Footer';
import Menu from '../Menu/Menu';
import {Link } from "react-router-dom";
import axios from '../../axios';
import { Helmet } from 'react-helmet';
import { ConfigContext } from '../../context/ConfigContext';

const Servicios = (props) => {
    const {config} = useContext(ConfigContext)
    const [servicios, setServicios] = useState([]);
    useEffect(()=>{
        let url = props.tipo === 'servicio' ? 'api/getservicios':'api/geteventossc'
        axios.get(url).then(res=>{
            setServicios(res.data)
        }).catch(e=>console.log(e))
    },[props.tipo])

    return (
    <>
      <Helmet>
            <title>{props.tipo === 'servicio' ? 'Servicios':'Eventos Sociales y Corporativos'} | {config.page_title ? config.page_title : ''}</title>
            <meta name="description" content={config.page_description ? config.page_description : ''}/>
            <link rel="image_src" href={config.logo ? config.logo : ''}/>
            <meta property="og:type" content="website"/>
            <meta property="og:title" content={config.page_title ? config.page_title : ''} />
            <meta property="og:description" content={config.page_description ? config.page_description : ''} />
            <meta property="og:image" content={config.logo ? config.logo : ''} />
            <link rel="canonical" href="https://corazondeorohechoenmexico.com.mx"/>
        </Helmet>

        <div className=' m-p-0'>
            <Menu/>
            <div className='row m-0 p-t-100'></div>
            {servicios.map((servicio,index)=>
            (index%2 === 0) ?
                <div className='m-p-0 text-img-rigth' key={servicio.id}>
                    <div className='container'>
                        <div className='row m-p-0'>
                            <div className='col-sm-12 col-md-6 cont-padding row servicio'>
                                <div className='col-2 col-sm-2 col-md-2 m-p-0'> 
                                    <div className='m-p-0 centrar servicios-num num-i'> 
                                        <div className=''>{index+1}</div> </div>
                                </div>
                                <div className='col-10 col-sm-10 col-md-10 servicios-i'>
                                    <div className='servicios-titulo text-d'>{servicio.nombre}</div>
                                </div>
                                <div>
                                    <div className='servicios-texto'>
                                        <p className='text-d'>
                                        {servicio.descripcion_corta}
                                        </p>
                                    </div>
                                    <div className='text-d'><br></br>
                                        <Link className="go-detaill" to={"/servicio/"+servicio.id}>Ver detalle</Link>
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-12 col-md-6 cont-padding'>
                                <div style={{height:'260px',}}>
                                    <img className='m-0 servicios-img' src={servicio.banner} alt={servicio.nombre}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='line-d' style={{}}>
                        <div className='row m-p-0'>
                            <div className='col-sm-12 col-md-6 m-p-0 line'></div>
                            <div className='col-sm-12 col-md-6 m-p-0'></div>
                        </div>
                    </div>
                </div>
            :
                <div className='m-p-0 text-img-left'  key={servicio.id}>
                    <div className='container'>
                        <div className='row m-p-0'>
                            <div className='col-sm-12 col-md-6 cont-padding movil'>
                                <div style={{height:'260px'}}>
                                    <img className='m-0 servicios-img' src={servicio.banner} alt={servicio.banner_alt}/>
                                </div>
                            </div>
                            <div className='col-sm-12 col-md-6 cont-padding row servicio'>
                                <div className='col-10 col-sm-10 col-md-10 servicios-d'>
                                    <div className='servicios-titulo text-i'>{servicio.nombre}</div>
                                </div>
                                <div className='col-2 col-sm-2 col-md-2 m-p-0'> 
                                    <div className='m-p-0 centrar servicios-num num-d'> 
                                        <div className=''>{index+1}</div> </div>
                                </div>
                                <div>
                                    <div className='servicios-texto'>
                                        <p className='text-i'>
                                            {servicio.descripcion_corta}
                                        </p>
                                    </div>
                                    <div className='text-i'>
                                        <Link className="go-detaill" to={"/servicio/"+servicio.id}>Ver detalle</Link> </div>
                                </div>
                            </div>
                            <div className='col-sm-12 col-md-6 cont-padding see-movil'>
                                <div style={{height:'260px',}}>
                                    <img className='m-0 servicios-img' src={servicio.banner} alt={servicio.nombre}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='line-d'>
                        <div className='row m-p-0'>
                            <div className='col-sm-12 col-md-6 m-p-0'></div>
                            <div className='col-sm-12 col-md-6 m-p-0 line'></div>
                        </div>
                    </div>
                </div>
            )}
            <Footer/>
        </div>
    </>
    );
};

export default Servicios;