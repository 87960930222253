import React, { Fragment, useEffect, useState, useContext } from 'react';
import { ConfigContext } from '../../context/ConfigContext';
import Footer from '../Footer/Footer';
import Menu from '../Menu/Menu';
import Galeria from '../Galeria/Galeria';
import axios from '../../axios';
import { Helmet } from 'react-helmet';


const Notas = () => {
    const {config} = useContext(ConfigContext)
    const [notas,setNotas] = useState([])
    
    useEffect(()=>{
        axios.get('api/phrases').then(res=>{
          //  console.log("notas",res.data)
            setNotas(res.data)
        }).catch(e=>console.log("error",e))
    },[])

    return (
    <Fragment>
        
        <Helmet>
            <title>Notas de amor | {config.page_title ? config.page_title : ''}</title>
            <meta name="description" content={config.home_text ? config.home_text : ''}/>
            <link rel="image_src" href={config.home_img ? config.home_img : ''}/>
            <meta property="og:type" content="website"/>
            <meta property="og:title" content={config.page_title ? config.page_title : ''} />
            <meta property="og:description" content={config.home_text ? config.home_text : ''} />
            <meta property="og:image" content={config.home_img ? config.home_img : ''} />
            <link rel="canonical" href="https://corazondeorohechoenmexico.com.mx"/>
        </Helmet>
    
        <div className=' m-p-0'>
            <Menu></Menu>
            <div className='row m-p-0'>
                <div className='col-12 col-sm-12 col-md-5 m-p-0 centrar'>
                    <div className='col-12 col-sm-12 col-md-3 m-p-0 movil-t-note'>
                        <div className='m-p-0'>
                            <h2 className='p-40 font-gral f-s-40' style={{color:'#814722', textTransform:'uppercase', marginLeft:'-30px!important',}}> 
                                <span className='m-p-0 nombreServicio f-w-600' style={{width:'100%'}}>Notas <br></br>de<br></br> Amor</span>
                            </h2>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-sm-12 col-md-7 row gral-banner' 
                    style={{background:'url(../../bg/notas.jpg), no-repeat',}}>
                    <img className='movil' src='../../bg/bg-degradado.png' />
                </div>
            </div>

            <div className='m-0' style={{padding:'40px 0'}}>
                <div className='container'>
                    <div className='row m-p-0'>
                    {notas.map((item,i)=>
                        (i%2 === 0) ?
                        <Fragment>
                            <div className='col-12 col-md-5 row m-p-0' key={item.id} >
                                <div className='col-4 col-md-4 m-p-0 centrar'>
                                    <div className='m-p-0'>
                                    <div className='centrar'>
                                        <img src={item.image} style={{height:120, width:120, borderRadius:'100%'}} />
                                    </div>
                                    <img src='../../bg/icon-5.png' style={{height:40, marginTop:'-30px'}} />
                                    </div>
                                </div>
                                <div className='col-8 col-md-8 m-0 ' style={{ padding:10,}}>
                                    <div className='m-0 font-semic f-s-16' style={{color:'#814722', padding:10}}>
                                        {item.title}
                                    </div>
                                    <div className='f-gral text-1' style={{padding:10}}>
                                        {item.phrase}
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 col-md-2 m-p-0 centrar'>
                                <img src='../../bg/bg-4.png' style={{height:'120px', maxWidth:'100%'}} />
                            </div>
                        </Fragment>
                        :
                        <Fragment>
                            <div className='col-12 col-sd-12 col-md-5 m-p-0 row' key={item.id}  >
                                <div className='col-8 col-md-8 m-0 ' style={{padding:10, }}>
                                    <div className='m-0 font-semic f-s-16 text-d' style={{color:'#814722', padding:10}}>
                                        {item.title}
                                    </div>
                                    <div className='f-gral text-d' style={{padding:10}}>
                                        {item.phrase}
                                    </div>
                                </div>
                                <div className='col-4 col-md-4 m-p-0 centrar'>
                                    <div className='m-p-0'>
                                    <div className='centrar'>
                                        <img src={item.image} style={{height:120, width:120, borderRadius:'100%'}} />
                                    </div>
                                    <img src='../../bg/icon-5.png' style={{height:40, marginTop:'-30px'}} />
                                    </div>
                                </div>
                            </div>
                            <div className='w-100 m-p-0 see-movil centrar' 
                            style={{margin:'auto', textAlign:'center'}}>
                                <img src='../../bg/bg-4.png' style={{margin:'auto', height:'120px', maxWidth:'100%', }} />
                            </div>
                        </Fragment>
                    )}
                    </div>
                </div>
            </div>
            <Galeria></Galeria>
        <Footer/>
        </div>
    </Fragment>
    );
};

export default Notas;