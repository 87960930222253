import React, { useContext } from 'react';
import { ConfigContext } from '../../context/ConfigContext';
import Footer from '../Footer/Footer';
import Menu from '../Menu/Menu';
import parse from 'html-react-parser';
import { Helmet } from 'react-helmet';

const Nosotros = () => {
    const {config} = useContext(ConfigContext)
    return (
    <>
      <Helmet>
            <title>Nosotros | {config.page_title ? config.page_title : ''}</title>
            <meta name="description" content={config.about_frase ? config.about_frase : ''}/>
            <link rel="image_src" href={config.about_img ? config.about_img : ''}/>
            <meta property="og:type" content="website"/>
			<meta property="og:title" content={config.page_title ? config.page_title : ''} />
			<meta property="og:description" content={config.page_description ? config.page_description : ''} />
			<meta property="og:image" content={config.about_img ? config.about_img : ''} />
            <link rel="canonical" href="https://corazondeorohechoenmexico.com.mx"/>
        </Helmet>

        <div className=' m-p-0'>
            <Menu></Menu>
            <div className='row m-p-0'>
                <div className='col-sm-12 col-md-5 gral-title'>
                    <div className='col-sm-12 col-md-9'>
                        <h2 className=' m-p-0 font-cursiva f-s-60'> Acerca de nosotros</h2>
                        <img src='../../Intro/logotipo_corazon-de-oro.png' />
                        <div className='p-t-b-20'>{config.about_us&&parse(config.about_us)}</div>
                    </div>
                </div>
                <div className='col-sm-12 col-md-7 row gral-banner' 
                    style={{background:'url('+config.about_img+'), no-repeat',}}>
                    <img className='movil' src='../../bg/bg-degradado.png' />
                </div>
            </div>

            <div className='row m-p-0'>
                <div className='col-sm-12 col-md-6 us'>
                    <div className='us-mision'> 
                        <h2> MISIÓN</h2> 
                    </div>
                </div>
                <div className='col-sm-12 col-md-6 us-text'>
                    <div className='col-sm-12 col-md-8'>
                        {config.mission&&parse(config.mission)}
                    </div>
                </div>
            </div>
            <div className='row m-p-0'>
                <div className='col-sm-12 col-md-6 us see-movil'>
                    <div className='us-vision'> 
                        <h2> VISIÓN</h2> 
                    </div>
                </div>
                <div className='col-sm-12 col-md-6 us-text'>
                    <div className='col-sm-12 col-md-8'>
                        {config.vission&&parse(config.vission)}
                    </div>
                </div>
                <div className='col-sm-12 col-md-6 us movil'>
                    <div className='us-vision'> 
                        <h2> VISIÓN</h2> 
                    </div>
                </div>
            </div>
           
            <div className='container p-t-b-60 cont-100'>
                <div className='row w-100 m-p-0'>
                    <div className='col-2 col-sm-2 col-md-2 m-p-0 centrar'>
                        <img className='icon-1-2' src='../../bg/icon-1.png' />
                    </div>
                    <div className='col-8 col-sm-8 col-md-8 m-p-0 info centrar'>
                        <p className=' m-p-0 text-c font-semic f-s-18 l-h-1-5'>{config.about_frase&&parse(config.about_frase)}</p>
                    </div>
                    <div className='col-2 col-sm-2 col-md-2 m-p-0 centrar'>
                        <img className='icon-1-2' src='../../bg/icon-2.png' />
                    </div>
                </div>
            </div>


            <div className='row us-valores'>
                <div className='col-md-6'>
                    <img className='' src='../../bg/valores.jpg' />
                </div>
            </div>
            <Footer></Footer>
        </div>
    </>
    );
};

export default Nosotros;