import React, { Fragment, useEffect, useState } from 'react';
// Import Swiper styles
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/zoom';
import 'swiper/css/autoplay';
import { Navigation, Autoplay } from 'swiper';
import axios from '../../axios';
import { Link } from 'react-router-dom';


const Galeria = () => {
    const [gallery,setGallery] = useState([])
    const [datos,setDatos] = useState({servicio:{nombre:""},image:"",servicio_id:0})

    useEffect(()=>{
        axios.get('api/get-slider-allery')
        .then(response=>{
            setGallery(response.data)
        }).catch(e=>console.log(e))
    },[])

    return (
    <Fragment>
          {/** MODAL GALERIA  */}
        <div className="modal fade" id="galleryModal" tabIndex="-1" aria-labelledby="galleryModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-xl">
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="modal-title f-s-18 m-p-0" id="galleryModalLabel">
                            <span className='movil'>Es parte de nuestro servicio:</span>
                            <img className='' src='../../bg/icon-1.png' style={{height:40,marginTop:'-20px'}} alt='corazon de oro' />
                            <span className='f-s-40 l-h-1-5 font-cursiva'> 
                            <Link className="text-decoration-none font-cursiva text-dorado" 
                                to={'/servicio/'+datos.servicio_id} 
                                onClick={e=>document.getElementById('galleryModal').hide() }
                                >{datos.servicio.nombre}</Link> 
                            </span>
                            <img className='' src='../../bg/icon-2.png' style={{height:40,marginTop:'-20px'}} alt="corazon de oro"/>
                        </div>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body centrar">
                        <img style={{objectFit:'cover!important', height:'70vh', maxWidth:'100%'}}
                        alt={datos.servicio.name}
                        src={datos.image} />
                    </div>
                </div>
            </div>
        </div>
        <div className='m-0' style={{padding:'6px'}}>
            <Swiper className='m-p-0'
                modules={[Navigation, Autoplay]}
                spaceBetween={6}
                slidesPerView={5}
                autoplay={{
                delay: 6500,
                disableOnInteraction: false,
                }}
                >
                {gallery.map(gal=>
                    <SwiperSlide  key={gal.id}>
                        <div className='slider-galerias' 
                        style={{background:'url('+gal.image+'), no-repeat',}}>
                            <a data-bs-toggle="modal" data-bs-target="#galleryModal" onClick={(e)=>setDatos(gal)}>
                                <div> Ver imagen &nbsp;&nbsp;<i className="fa-brands fa-phabricator"></i></div>
                            </a>
                        </div>
                    </SwiperSlide>
                )}
            </Swiper>
        </div>
    </Fragment>
    
    );
};

export default Galeria;