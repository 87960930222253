import React, { useState, createContext, useEffect} from 'react'
import axios from '../axios' 
import {Helmet} from "react-helmet";

export const ConfigContext = createContext()
export const ConfigProvider = props => {

	const [config, setConfig] = useState({})

	useEffect(()=>{
        getConfig()
	},[])

    const getConfig = () =>{
        axios.get('api/config/1').then(r=>{
            setConfig(r.data)
           // document.getElementsByTagName("META")[2].content=r.data.page_description;
            localStorage.setItem('configs', JSON.stringify(r.data));
        }).catch(e=>console.log(e))
	}

	return (
		<ConfigContext.Provider value={{config}}>
			{props.children}
		</ConfigContext.Provider>
	)
}
