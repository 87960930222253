import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { ConfigContext } from '../../context/ConfigContext';

const Footer = () => {
    const {config} = useContext(ConfigContext)
    const year = new Date().getFullYear();
    return (
        <div className='m-p-0' style={{background:'#1f1f1f', }}>
            <div className='container'>
                <div className='menu-footer row'>
                    <div className='col-sm-6 col-md-2 m-p-0 movil-center'>
                        <a className="footer-logo" href="#" alt='Corazón de oro'>
                            <img src='../../Intro/footer_corazon-de-oro.png' />
                        </a>
                    </div>
                    <div className='col-sm-12 col-md-8 row footer-cont-menu movil'>
                        <div className='col col-md-auto movil'> <Link to="/casa">Casa</Link></div>
                        <div className='col col-md-auto movil'> <Link to="/nosotros">Nosotros</Link> </div>
                        <div className='col col-md-auto movil'> <Link to="/servicios">Servicios</Link> </div>
                        <div className='col col-md-auto movil'> <Link to="/eventos">Eventos Sociales y Corporativos</Link> </div>
                        <div className='col col-md-auto movil'> <Link to="/notas">Notas de Amor</Link> </div>
                        <div className='col col-md-auto movil'> <Link to="/contacto">Contacto</Link> </div>
                    </div>
                    <div className='col-sm-6 col-md-2 row m-p-0'>
                        {config.phone&&
                        <p className='col col-md-4 footer-redes'>
                            <a className="m-p-0" href={'https://wa.me/52'+config.phone+'?text=Me%20gustaria%20conocer%20mas%20de%20sus%20servicios'} target="_blank" rel='noreferrer'>                                                
                                <i className="fa-brands fa-whatsapp"></i>
                            </a>
                        </p>
                        }
                        {config.url_insta&&
                            <p className='col col-md-4 footer-redes'> <a className="m-p-0" href={'https://'+config.url_insta} target="_blank" rel='noreferrer'>
                            <i className="fa-brands fa-instagram"></i></a></p>
                        }
                        {config.url_insta_2&&
                        <p className='col col-md-4 footer-redes'><a className="m-p-0" href={'http://'+config.url_insta_2} target="_blank" rel='noreferrer'>
                            <i className="fa-brands fa-instagram"></i></a></p>
                        }
                    </div>
                    <div className='col-12 centrar'>{year} Todos los derechos reservados Corazón de oro Hecho en México.</div>
                </div>
            </div>
        </div>
    );
};

export default Footer;