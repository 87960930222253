import React, { useContext } from 'react';
import {Link } from "react-router-dom";
import { ConfigContext } from '../../context/ConfigContext';

const Menu = (props) => {
    const {config} = useContext(ConfigContext)
    var logo = '../../Intro/logotipo_corazon-de-oro.png'

    return (
        <div id='menu-corazon-de-oro'>
            <nav className="navbar navbar-expand-lg navbar-light m-p-0">
                <div className="container-fluid m-p-0">
                    <Link className="navbar-brand cont-logo" to="/casa">
                        <img src={config.logo?config.logo:logo} alt="logo corazon de oro"/>
                    </Link>
                    
                    <div className="collapse navbar-collapse m-0" id="navbarNavDropdown"  style={{padding:'0 6px'}}>
                        <ul className="navbar-nav nav-corazon-de-oro"  style={{width:'100%'}}>
                       
                            <li className="nav-item  col-md-auto">
                                <Link className="nav-link active" to="/casa">Casa</Link>
                            </li>
                            <li className="nav-item  col-md-auto">
                                <Link className="nav-link active" to="/nosotros">Nosotros</Link>
                            </li>
                            <li className="nav-item  col-md-auto">
                                <Link className="nav-link active" to="/servicios">Servicios</Link>
                            </li>
                            <li className="nav-item  col-md-auto">
                                <Link className="nav-link active" to="/eventos">Eventos Sociales y Corporativos</Link>
                            </li>
                            <li className="nav-item  col-md-auto">
                                <Link className="nav-link active" to="/notas">Notas de Amor</Link>
                            </li>
                            <li className="nav-item  col-md-auto">
                                <Link className="nav-link active" to="/contacto">Contacto</Link>
                            </li>
                            
                        </ul>
                    </div>
                    <a className="navbar-brand a-icon-menu movil" href={'https://wa.me/52'+config.phone+'?text=Me%20gustaria%20conocer%20mas%20de%20sus%20servicios'} target="_blank" rel='noreferrer'>                        
                        <p><i className="fa-brands fa-whatsapp"></i></p>
                    </a>
                    {config.url_insta&&
                    
                        <a className="navbar-brand a-icon-menu movil" href={'https://'+config.url_insta} target="_blank" rel='noreferrer'>
                            <p><i className="fa-brands fa-instagram"></i></p>
                        </a>
                    }
                    {config.url_insta_2&&
                    <a className="navbar-brand a-icon-menu movil" href={'https://'+config.url_insta_2} target="_blank" rel='noreferrer'>
                        <p><i className="fa-brands fa-instagram"></i></p>
                    </a>
                    }
                    
                    <button className="navbar-toggler btn-menu"
                        type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                </div>
            </nav>
        </div>
    );
};

export default Menu;